.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  /*text-align: center;*/
}

.steps-action {
  margin-top: 24px;
}

.modalBarraProgresso {
  margin-right: auto;
  right: 8px;
}

.modalBarraProgressoText {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}


