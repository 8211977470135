/* Header */
.headerRotina {
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: solid 0.1rem gray;
}

.headerRotina .options {
  display: flex;
  align-items: center;

  margin-right: 2rem;
  width: auto;
}

.headerRotina .ant-select {
  width: 10vw;
}

.headerRotina label {
  margin: 0;
}


/* Body */
.bodyRotina {
  display: flex;
  max-height: 75vh;
}

.calendario {
  width: 70vw;
  padding-right: 2rem;
  overflow: hidden;
}

.calendario .ant-fullcalendar-header {
  display: none;
}

.calendario .ant-fullcalendar-calendar-body {
  padding: 0;
}

.detalhes {
  display: inline;
  width: 30vw;
}

.containerCardsRotinaVendedor {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 2rem;
}

.cardsRotinaVendedor {
  text-align: center;

  width: 30%;
  height: fit-content;
  padding: 1rem;
  border-radius: 1rem;

  background-color: #1890ff;
  color: white;

  white-space: nowrap;
}

.contentCardRotina {
  height: auto;
  font-size: 2.6rem;
}

.linhaDoTempo {
  height: 85%;

  border: solid 0.2rem #e8e8e8;

  overflow-y: scroll;
}

.cardLinhaDoTempo {
  display: flex;
  justify-content: space-between;

  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;

  color: white;
  font-size: 1.8rem;
}

.filterButton {
  margin-right: 2rem;
}

.dateCell {
  padding-left: 2rem;
}

@media only screen and (max-width: 1600px) {
  .dateCell li {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1316px) {
  .cardsRotinaVendedor {
    font-size: 1.2rem;
  }
}

@media only screen and (max-height: 700px) {
  .calendario {
    overflow: visible;
  }
}