.containerIndicacao {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerIndicacao label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerIndicacao button {
    margin-left: 10px;
}

/* .containerIndicacao .ant-calendar-picker-input.ant-input {
    width: 250px;
} */

.left {
    float: left;
    width: 50%;
    box-sizing: border-box;
}

.right {
    float: right;
    width: 50%;
    box-sizing: border-box;
}

.divTableIndicacaoLoja {
    position: relative;
    margin-top: 50px !important;
}

.fff {
    background: #fff;
}

.eee {
    background: #eee;
}

.ddd {
    background: #ddd;
}