.premiacaoValor {
    border: solid 1px;
    border-radius: 4px !important;
    width: 220px;
    padding-left: 5px;
    border-color: rgb(218, 215, 215);
    height: 33px;
    width: 120px;
}

.btnLoyalty {
    width: 1px;
    height: 25px;
}

.btnEditLoyalty {
    color: white !important;
    background-color: #EC9720 !important;
}

.btnDeleteLoyalty {
    color: white !important;
    background-color: #ec5020 !important;
}

.iconLoyalty {
    position: relative !important;
    left: -7px;
    top: -2px
}

.divPremiacaoValor {
    margin-bottom: 20px;
}

.divPontosParaTroca {
    margin-bottom: 25px;    
}

.iconCodigoProdutoHelp {
    position: relative;
    margin-left: 5px;
    top: -2px
}

.tableSetor {
    color: #ec5020;
    position: relative;
    top: 30px;
    left: -0px;
    width: 450px;
}

.empresa-crud-input:disabled {
    background-color: lightgray !important;
}