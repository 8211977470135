.containerExtratoAtendimentos {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
    display: flex;
    flex-wrap: wrap;
}

.containerExtratoAtendimentos .child {
    height: 100px;
    margin: 10px;
    margin-left: 10px;
}

.containerExtratoAtendimentos .child label {
    margin-left: 5px;
}

.containerExtratoAtendimentos .child button {
    width: 150px !important;
    margin-left: 10px;
}

.left {
    float: left;
    width: 50%;
    box-sizing: border-box;
}

.right {
    float: right;
    width: 50%;
    box-sizing: border-box;
}

.divTableHistoricoAtendimento {
    position: relative;
    margin-top: 50px !important;
}

.fff {
    background: #fff;
}

.eee {
    background: #eee;
}

.ddd {
    background: #ddd;
}

.excel-button {
    position: relative;
    top: -0px;
    background-color: #00b050;
    color: #fff;
    border: none;
    box-shadow: none;
}

.excel-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.excel-button:hover {
    background-color: #00b050;
    color: #fff;
}


.excel-button-v2 {
    position: relative;
    top: -10px;
    background-color: #00b050;
    color: #fff;
    border: none;
    box-shadow: none;
}

.excel-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.excel-button:hover {
    background-color: #00b050;
    color: #fff;
}