.headerVendedor {
  display: flex;
  align-items: center;
}

.selectContainer {
  display: flex;
  align-items: center;
  width: 25%;

  overflow: hidden;
  white-space: nowrap;
}

#selectEmpresa {
  margin-left: 0.625rem;
}

.selectContainer label {
  margin: 0;
  font-weight: bold;
}

.headerVendedor .ant-btn {
  max-width: fit-content;
  width: 10%;

  overflow: hidden;
  white-space: nowrap;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.footerContainer {
  text-align: center;
}