@media only screen and (max-width: 600px) {
  body {
    width: 100% !important;
  }

  .divTable {
    width: 100% !important;
  }
}

.ant-table {
  display: block;

  @media screen and (max-width: 600px) {
    &-thead {
      display: none !important;
      white-space: nowrap;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem !important;
        }

        &:last-of-type {
          padding-bottom: 1rem !important;
        }
      }

      >th,
      >td {
        display: block !important;
        width: auto !important;
        border: none !important;
        padding: 0 1rem !important;
        font-size: 1.1rem !important;

        &:last-child {
          border-bottom: 1px solid #eee !important;
        }
      }
    }
  }
}

.searchContainer {
  margin-bottom: 1.25rem;
}

.textError {
  color: red;
  display: block;
  width: 220px;
  overflow-wrap: break-word;
  /*margin-left: 150px;*/
  text-align: justify;
  text-justify: inter-word;
}

.icon {
  position: relative;
  top: -3px;
}

.alphabet {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.alphabet button {
  margin: 2px;
}

.noteMessageBox {
  display: flex;
  align-items: center;

  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;

  background-color: #3698f4;
  color: white;

  font-size: 1.6rem;
}

.ant-table-footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
}

.ant-table-footer::before {
  background-color: white;
}

.checkboxContainer span {
  font-size: 1.4rem;
  font-weight: normal;
}
