@media screen and (max-width: 1820px) {
  .cps-banner.style-6 .cps-banner-image img {
    width: 700px;
  }
}
@media screen and (max-width: 1599px) {
  .cps-banner.style-4 .cps-banner-image img {
    max-width: 1170px;
  }
}
/*** For media having less than 1200px width ***/
@media screen and (max-width: 1199px) {
  footer .cps-footer-lower .footer-menu li + li {
    margin-left: 25px;
  }

  .cps-banner.style-9 .cps-banner-title {
    font-size: 4.8rem;
  }

  .cps-curve-bg-2 {
    padding-bottom: 160px;
  }

  .cps-banner.style-6 .cps-banner-image img {
    width: 592px;
  }

  .cps-banner.style-4 .cps-banner-image img {
    max-width: 950px;
  }

  .instruction-tabs .nav-tabs li a {
    font-size: 1.6rem;
  }

  .cps-banner.style-13 .cps-banner-image .double-mock .back-mock {
    width: 500px;
  }

  .cps-banner.style-13 .cps-banner-image .double-mock .front-mock {
    bottom: 56px;
  }

  .cps-section-header.style-3 {
    margin-top: 45px;
  }

  .connection-mock .connection-logoes {
    margin-left: -320px;
  }

  .connection-mock .logo-mock + .connection-logoes {
    margin-right: -320px;
  }

  .navbar-default .navbar-nav > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }

  .featured-mock {
    max-width: 100%;
    margin-top: 100px;
  }
  .style-2 .featured-mock {
    margin-top: 0;
  }
  .style-3 .featured-mock {
    margin-top: 50px;
  }

  .mocked-features.style-2 .feature-item + .feature-item {
    margin-top: 35px;
  }

  .top-120p {
    margin-top: 60px;
  }

  .screenshot-container .screenshot-mock {
    max-width: 196px;
  }

  .navbar-default .navbar-right {
    margin-right: -15px;
  }

  .cps-section-header.style-2.text-left {
    margin-top: 0;
  }

  .screenshot-container {
    -webkit-perspective: 950px;
    -o-perspective: 950px;
    perspective: 950px;
  }

  .screenshot-slick-container {
    padding-top: 41px;
  }

  .screenshot-slick-container .screenshot-mock-2 {
    max-width: 215px;
  }

  .cps-member .cps-member-socials a + a {
    margin-left: 5px;
  }

  .post-list .post-content {
    margin-top: 0;
    padding-left: 0;
  }

  .group-image {
    height: 330px;
  }
  .group-image .group-front-img {
    width: 530px;
    margin-left: -265px;
  }

  .cps-banner.style-14 .cps-banner-item {
    height: 768px;
  }
  .cps-banner.style-14 .cps-banner-image {
    margin-top: 190px;
  }

  .cps-right-img-wrap img {
    max-width: 560px;
    height: auto;
  }

  .content-half {
    padding: 120px 0;
  }
}
/*** For media having less than 9992px width ***/
@media screen and (max-width: 991px) {
  /*** Navigation ***/
  .navbar-default .navbar-header {
    float: none;
  }
  .navbar-default .navbar-left,
  .navbar-default .navbar-right {
    float: none !important;
  }
  .navbar-default .navbar-toggle {
    display: block;
    border-radius: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: 23px;
    margin-bottom: 23px;
    margin-right: 0;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .navbar-default .navbar-toggle.collapsed {
    background-color: transparent;
  }
  .navbar-default .navbar-collapse {
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    background-color: #ffffff;
  }
  .navbar-default .navbar-collapse .navbar-nav {
    max-height: 400px;
    overflow: auto;
  }
  .navbar-default .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-default .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-default .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-default .navbar-nav > li {
    float: none;
  }
  .navbar-default .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-default .collapse.in {
    display: block !important;
  }
  .navbar-default .navbar-nav li {
    clear: both;
  }
  .navbar-default .navbar-nav li.dropdown a {
    display: inline-block;
  }
  .navbar-default .navbar-nav li.dropdown:before {
    content: "\f067";
    font-family: "FontAwesome";
    float: right;
    padding: 10px 15px;
    line-height: 20px;
  }
  .navbar-default .navbar-nav li.dropdown .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    position: relative;
    width: 100%;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
  .navbar-default .navbar-right {
    margin-right: 0;
  }
  .navbar-default.affix .navbar-toggle {
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .navbar-default.style-1 .navbar-nav > li.devider {
    display: none;
  }
  .navbar-default.style-2 .navbar-toggle, .navbar-default.style-3 .navbar-toggle, .navbar-default.style-6 .navbar-toggle, .navbar-default.style-7 .navbar-toggle, .navbar-default.style-10 .navbar-toggle {
    border-color: #ffffff;
    background-color: #ffffff;
  }
  .navbar-default.style-2 .navbar-toggle.collapsed, .navbar-default.style-3 .navbar-toggle.collapsed, .navbar-default.style-6 .navbar-toggle.collapsed, .navbar-default.style-7 .navbar-toggle.collapsed, .navbar-default.style-10 .navbar-toggle.collapsed {
    background-color: transparent;
  }
  .navbar-default.style-2 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-3 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-6 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-7 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-10 .navbar-toggle.collapsed .icon-bar {
    background-color: #ffffff;
  }
  .navbar-default.style-2 .navbar-nav li:before, .navbar-default.style-3 .navbar-nav li:before, .navbar-default.style-6 .navbar-nav li:before, .navbar-default.style-7 .navbar-nav li:before, .navbar-default.style-10 .navbar-nav li:before {
    color: #ffffff;
  }

  /*** Others ***/
  .cps-footer-widget-area .col-sm-6:nth-child(2n + 1) {
    clear: left;
  }

  .sm-text-center {
    text-align: center !important;
  }

  .cps-subsection-title {
    margin-top: 0;
  }

  .cps-banner.style-4 .cps-banner-item .cps-banner-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-top: 32px;
  }

  .instruction-tabs .nav-tabs li {
    margin-bottom: 15px;
  }

  .sm-bottom-30 {
    margin-bottom: 30px;
  }

  .cps-video-section {
    height: 350px;
  }

  .cps-banner.style-7 .cps-banner-form {
    margin: 20px auto 0;
  }

  .cps-banner.style-9 .cps-banner-form {
    max-width: 470px;
    margin: 25px auto 0;
  }

  .cps-service-box {
    padding: 15px !important;
  }

  .cps-section-padding {
    padding: 40px 0;
  }

  .cps-banner .cps-banner-item-14 {
    background-image: none;
  }

  .cps-banner.style-13 .cps-banner-form {
    margin: 25px auto 0;
  }

  .cps-banner.style-12 .cps-banner-item .cps-banner-content {
    padding: 140px 0 100px;
  }

  .connection-mock {
    padding: 0;
  }

  .connection-mock .connection-logoes {
    position: static;
    margin: 0 auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    float: none;
    display: inline-block;
    text-align: center;
    clear: both;
    padding: 0;
  }

  .connection-mock .connection-logoes .connection-logo-item {
    margin: 15px;
  }

  .connection-mock .logo-mock {
    padding: 150px;
    display: block;
  }

  .connection-mock .logo-mock + .connection-logoes {
    margin: 0 auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  .connection-mock .logo-mock + .connection-logoes .connection-logo-item {
    margin: 15px;
  }

  .top-120p {
    margin-top: 0;
  }

  .app-landing-extra-feature-1 {
    padding: 60px 0 !important;
  }

  .screenshot-container {
    padding: 7px 0;
  }

  .screenshot-container .screenshot-mock {
    max-width: 150px;
  }

  .cps-member.style-3 {
    max-width: 270px;
    margin: 0 auto 30px;
  }

  .mocked-features.style-2 .cps-section-title {
    color: #262626 !important;
  }

  .cps-section-header.style-2 .cps-section-title br {
    display: none;
  }

  .hiw-video-style-2 .cps-overlay {
    padding: 120px 0;
  }

  .screenshot-slick-container .screenshot-mock-2 {
    max-width: 288px;
  }

  .screenshot-slick-container {
    padding-top: 60px;
  }

  .mocked-features.style-3 .col-md-4:first-child .feature-item .feature-icon {
    float: left;
    margin-right: 30px;
    margin-left: 0;
  }

  .mocked-features .col-xs-12:first-child {
    margin-bottom: 30px;
  }

  .mocked-features.style-3 .col-md-4:first-child .feature-item .feature-body {
    text-align: left;
  }

  .cps-section-header .cps-section-title br {
    display: none;
  }

  .post-single .post-share label {
    display: block;
  }

  .post-list .post-content {
    margin-top: 20px;
  }

  ul.nav.navbar-nav.navbar-social.navbar-right {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin: 0 15px 0 0;
  }

  .group-image {
    height: 260px;
  }
  .group-image .group-front-img {
    width: 400px;
    margin-left: -200px;
  }
  .group-image .group-back-img-1,
  .group-image .group-back-img-2 {
    width: 260px;
  }

  .screenshot-container-block {
    padding-bottom: 30px;
  }

  .screenshot-container #previous,
  .screenshot-container #next {
    top: calc(100% + 65px);
  }

  .screenshot-container #previous {
    left: 60px;
  }

  .screenshot-container #next {
    right: 60px;
  }

  .screenshot-mock {
    top: 0;
  }

  .cps-banner.style-14 .cps-banner-item .cps-banner-content {
    top: 50%;
    margin-top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
  }
  .cps-banner.style-14 .cps-banner-item .cps-banner-title .typer-fixed {
    display: block;
    width: 100%;
    text-align: center;
  }
  .cps-banner.style-14 .cps-banner-item .cps-banner-title .typer {
    width: 100%;
    display: block;
    text-align: center;
    height: 5.8rem;
  }

  .ticksy-cta {
    min-height: 800px;
  }

  .cps-banner.style-15 .cps-banner-item {
    height: 600px;
  }

  .cps-roadmap-main-wrap {
    width: 50%;
  }

  .cps-roadmap-right {
    width: 50%;
  }
  .cps-roadmap-right .cps-right-img-wrap {
    left: 30px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
  .cps-roadmap-right .cps-right-img-wrap img {
    max-width: none;
  }

  footer .cps-roadmap-right .cps-right-img-wrap {
    right: 30px;
  }

  .cps-banner.style-16 .cps-banner-item .overlay {
    height: 600px;
  }

  .bubble-fact-md {
    left: 0px;
  }

  .bubble-fact-sm {
    left: 180px;
  }

  .bubble-fact-xs {
    left: 135px;
    top: 235px;
  }

  .account-form.style-2 input:not([type=submit]):not([type=radio]):not([type=checkbox]), .account-form.style-2 select, .account-form.style-2 textarea, .account-form.style-2 [type=submit] {
    margin-top: 10px;
  }

  footer .cps-footer-widget-area .col-xs-4:nth-child(3n + 1) {
    clear: left;
  }

  .navbar-roadmap {
    width: 100%;
    left: 0;
    right: 0;
    height: 60px;
    text-align: center;
  }

  .cps-sidebar-container {
    width: calc(100% - 30px);
    max-width: none;
  }
  .cps-sidebar-container .navbar-brand {
    margin: 0;
    height: 60px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .cps-roadmap-main-wrap {
    width: 100%;
  }

  .cps-roadmap-section {
    padding-bottom: 120px;
    margin-top: 120px;
  }

  .cps-roadmap-right {
    width: 100%;
    position: static;
    margin-top: 60px;
    padding: 30px;
    display: block !important;
  }
  .cps-roadmap-right .cps-right-img-wrap, .cps-roadmap-right .cps-right-content-wrap {
    position: static;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .cps-roadmap-right .cps-right-img-wrap img, .cps-roadmap-right .cps-right-content-wrap img {
    max-width: 100%;
  }
  .cps-roadmap-right .cps-right-content-wrap {
    width: 100%;
    max-width: none;
    margin: 30px auto;
  }
  .cps-roadmap-right .copyright {
    position: static;
    margin-top: 50px;
  }

  .cps-roadmap-section + .cps-roadmap-section {
    margin-top: 0;
  }

  footer.cps-roadmap-section {
    padding-bottom: 0;
  }

  .cps-roadmap-right .cps-right-content-wrap .cps-services .col-md-4:nth-child(3n + 1) {
    clear: none;
  }

  .cps-roadmap-right .cps-right-content-wrap .cps-services .col-xs-6:nth-child(2n + 1) {
    clear: left;
  }

  .cps-banner.style-17 .cps-banner-item {
    height: 700px;
  }

  .content-half {
    padding: 60px 0;
  }

  .list-inline-iconic li {
    display: block;
  }
  .list-inline-iconic li + li {
    margin-left: 0;
  }
}
/*** Media with less than 768px width ***/
@media screen and (max-width: 767px) {
  .navbar-default {
    min-height: 60px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-brand {
    margin-top: 0;
    margin-bottom: 0;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-default .navbar-toggle.collapsed {
    background-color: transparent;
  }
  .navbar-default .navbar-toggle.collapsed .icon-bar {
    top: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    top: 0;
    margin: 0;
  }
  .navbar-default .navbar-toggle.collapsed .icon-bar + .icon-bar {
    top: 50%;
    margin-top: -1px;
    opacity: 1;
  }
  .navbar-default .navbar-toggle.collapsed .icon-bar + .icon-bar + .icon-bar {
    top: auto;
    margin: 0;
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .navbar-default .navbar-toggle {
    background-color: transparent;
    margin-right: 15px;
    margin-top: 19px;
    margin-bottom: 19px;
    width: 34px;
    height: 22px;
    padding: 0;
    border: none;
    position: relative;
  }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }
  .navbar-default .navbar-toggle .icon-bar {
    width: 32px;
    position: absolute;
    margin-top: -1px;
    top: 50%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  .navbar-default .navbar-toggle .icon-bar + .icon-bar {
    opacity: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .navbar-default .navbar-toggle .icon-bar + .icon-bar + .icon-bar {
    opacity: 1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .navbar-default .navbar-right {
    margin-right: -15px;
  }
  .navbar-default.style-2 .navbar-toggle.collapsed, .navbar-default.style-3 .navbar-toggle.collapsed, .navbar-default.style-6 .navbar-toggle.collapsed, .navbar-default.style-7 .navbar-toggle.collapsed, .navbar-default.style-10 .navbar-toggle.collapsed {
    border: 1px solid #ffffff;
    height: auto;
    width: auto;
    padding: 9px 10px;
  }
  .navbar-default.style-2 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-3 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-6 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-7 .navbar-toggle.collapsed .icon-bar, .navbar-default.style-10 .navbar-toggle.collapsed .icon-bar {
    background-color: #ffffff !important;
    position: static;
    width: 22px;
  }
  .navbar-default.style-2 .navbar-toggle.collapsed .icon-bar + .icon-bar, .navbar-default.style-3 .navbar-toggle.collapsed .icon-bar + .icon-bar, .navbar-default.style-6 .navbar-toggle.collapsed .icon-bar + .icon-bar, .navbar-default.style-7 .navbar-toggle.collapsed .icon-bar + .icon-bar, .navbar-default.style-10 .navbar-toggle.collapsed .icon-bar + .icon-bar {
    margin-top: 4px;
  }
  .navbar-default.style-2 .navbar-toggle, .navbar-default.style-3 .navbar-toggle, .navbar-default.style-6 .navbar-toggle, .navbar-default.style-7 .navbar-toggle, .navbar-default.style-10 .navbar-toggle {
    border: 1px solid #ffffff;
    height: auto;
    width: auto;
    padding: 9px 10px;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .navbar-default.style-2 .navbar-toggle .icon-bar, .navbar-default.style-3 .navbar-toggle .icon-bar, .navbar-default.style-6 .navbar-toggle .icon-bar, .navbar-default.style-7 .navbar-toggle .icon-bar, .navbar-default.style-10 .navbar-toggle .icon-bar {
    background-color: #ffffff !important;
    position: static;
    width: 22px;
    margin-top: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .navbar-default.style-2 .navbar-toggle .icon-bar + .icon-bar, .navbar-default.style-3 .navbar-toggle .icon-bar + .icon-bar, .navbar-default.style-6 .navbar-toggle .icon-bar + .icon-bar, .navbar-default.style-7 .navbar-toggle .icon-bar + .icon-bar, .navbar-default.style-10 .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px !important;
    opacity: 1;
  }
  .navbar-default.style-2 .navbar-toggle .icon-bar + .icon-bar + .icon-bar, .navbar-default.style-3 .navbar-toggle .icon-bar + .icon-bar + .icon-bar, .navbar-default.style-6 .navbar-toggle .icon-bar + .icon-bar + .icon-bar, .navbar-default.style-7 .navbar-toggle .icon-bar + .icon-bar + .icon-bar, .navbar-default.style-10 .navbar-toggle .icon-bar + .icon-bar + .icon-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .navbar-default.affix .navbar-toggle {
    margin-top: 19px;
    margin-bottom: 19px;
  }

  .cps-section-title br {
    display: none;
  }

  .cps-banner .cps-banner-item,
  .cps-banner .cps-banner-item .overlay {
    height: 600px !important;
  }

  .cps-section-padding {
    padding: 45px 0 60px;
  }
  .cps-section-padding .cps-bottom-0 {
    padding-bottom: 0;
  }
  .cps-section-padding.cps-curve-bg-2 {
    padding-bottom: 120px;
  }

  .cps-member {
    max-width: 370px;
    margin: 0 auto 30px;
  }

  .cps-post {
    max-width: 370px;
    margin: 0 auto 52px;
  }

  .xs-text-center {
    text-align: center !important;
  }

  .cps-services .col-xs-6:nth-child(2n + 1) {
    clear: left;
  }

  .xs-bottom-30 {
    margin-bottom: 30px;
  }

  .cps-cta.style-3 {
    padding: 60px 0;
  }

  .cps-cta.style-4 {
    padding: 60px 0;
  }

  .cps-banner .cps-banner-title br,
  .cps-banner .cps-banner-text br {
    display: none;
  }

  .option-box {
    max-width: 570px;
    margin: 0 auto 30px;
  }

  .feature-tabs .nav-tabs li {
    width: 100%;
    margin: 0 0 12px;
  }
  .feature-tabs .nav-tabs li a .fa {
    display: inline-block;
    vertical-align: middle;
    font-size: 2.2rem;
    margin-right: 15px;
  }
  .feature-tabs .nav-tabs li a br {
    display: none;
  }

  .sm-bottom-30 {
    margin-bottom: 30px;
  }

  .cps-posts {
    margin-bottom: 6px;
  }

  input:not([type=submit]):not([type=radio]):not([type=checkbox]):last-child,
  select:last-child,
  textarea:last-child {
    margin-bottom: 10px;
  }

  .cps-section-header.style-3 {
    margin: 0;
  }

  .connection-content {
    margin-left: 0;
  }

  .mocked-features .col-md-4:first-child .feature-item {
    text-align: left;
  }

  .screenshot-container {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }

  .screenshot-container .screenshot-mock {
    max-width: 130px;
  }

  .style-3 .cps-testimonials-wrap {
    max-width: none;
    margin: 0;
  }

  .how-it-work-process .process-item + .process-item {
    margin-left: 60px;
  }

  .how-it-work-process .process-item + .process-item:before {
    left: -40px;
  }

  .cps-curved-bg-2 .mocked-features {
    color: #262626;
  }

  .screenshot-slick-container .screenshot-mock-2 {
    max-width: 238px;
  }

  .screenshot-slick-container {
    padding-top: 46px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  .mocked-features.style-3 {
    margin-bottom: 0;
  }

  .sidebar {
    margin-top: 60px;
  }

  .comingsoon-banner .cps-banner-content {
    margin-top: 0;
  }

  .bubble-fact-md {
    left: 100px;
  }

  .bubble-fact-sm {
    left: 370px;
  }

  .bubble-fact-xs {
    left: 282px;
    top: 314px;
  }

  #driverRegistration {
    margin-top: 30px;
  }

  .img-half {
    display: none;
  }

  .cps-section-half-half .content-half {
    background-color: #f7f7f7;
  }

  .cps-section-half-half.alt-style .content-half {
    background-color: #ffffff;
  }
}
/*** Device less than 640px ***/
@media screen and (max-width: 639px) {
  .instruction-tabs .nav-tabs li {
    width: 100%;
  }

  .cps-video-section {
    height: 300px;
  }

  .cps-cta.pos-absolute {
    position: static;
  }

  .big-play-btn {
    margin-top: 0;
  }

  .screenshot-container .screenshot-mock {
    display: none;
  }

  .screenshot-container .swiper-slide.swiper-slide-active {
    border-radius: 0;
  }

  .cps-banner-slider-2 .slick-dots {
    bottom: 0;
  }

  .how-it-work-process .process-item {
    height: 120px;
    width: 120px;
    padding: 38px 15px;
  }

  .screenshot-slick-container {
    max-width: 550px;
    padding-top: 36px;
  }

  .screenshot-slick-container .screenshot-mock-2 {
    max-width: 198px;
  }

  .post-list .post-content {
    margin-top: 0;
  }

  .cps-bg-round-1 {
    width: 720px;
    height: 720px;
  }

  .cps-bg-round-2 {
    width: 720px;
    height: 720px;
  }

  .app-landing-extra-feature-1 {
    background-color: #f7f7f7;
    margin-top: 40px;
  }
  .app-landing-extra-feature-1 .cps-bg-round-1,
  .app-landing-extra-feature-1 .cps-bg-round-2,
  .app-landing-extra-feature-1 .cps-bg-round-3,
  .app-landing-extra-feature-1 .cps-bg-round-4 {
    display: none !important;
  }

  .cps-app-testimonial-1 {
    background-color: #f7f7f7;
  }
  .cps-app-testimonial-1 .cps-bg-round-1,
  .cps-app-testimonial-1 .cps-bg-round-2,
  .cps-app-testimonial-1 .cps-bg-round-3,
  .cps-app-testimonial-1 .cps-bg-round-4 {
    display: none !important;
  }

  .bubble-fact-md {
    left: 60px;
  }

  .bubble-fact-sm {
    left: 312px;
  }

  .bubble-fact-xs {
    left: 235px;
    top: 270px;
  }
}
/*** Device less than 580px **/
@media screen and (max-width: 579px) {
  .col-xs-6 {
    width: 100%;
  }

  .cps-case-study {
    margin: 0 auto;
    max-width: 370px;
  }

  .cps-banner .cps-banner-item,
  .cps-banner .cps-banner-item .overlay {
    height: 460px !important;
  }

  .cps-banner .cps-banner-title {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }

  .cps-section-header .cps-section-title {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  .filter-button-group button {
    width: 100%;
  }
  .filter-button-group button + button {
    margin-left: 0;
  }

  .cps-button-group .btn,
  .button-group .btn {
    clear: both;
    display: block;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  .cps-button-group .btn + .btn,
  .button-group .btn + .btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .cps-testimonial-container {
    padding: 15px;
  }

  .big-play-btn {
    margin-top: -25px;
  }

  .cps-banner.style-9 .cps-banner-title {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }

  .cps-cta-download a {
    max-width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .cps-cta-download a + a {
    margin-top: 15px;
    margin-left: auto !important;
  }

  .cps-video-section {
    height: 160px;
  }

  .cps-banner .cps-banner-title {
    text-align: center;
  }
  .cps-banner .cps-banner-text {
    text-align: center;
  }
  .cps-banner .cps-banner-subtitle {
    text-align: center;
  }

  .big-mock-padding {
    margin: 30px -15px;
  }

  .mini-fact .mini-fact-count {
    font-size: 2.4rem;
  }

  .how-it-work-process .process-item {
    height: 100px;
    width: 100px;
    padding: 28px 15px;
  }

  .fact-style-2 .col-xs-4 {
    width: 100%;
  }

  .screenshot-slick-container {
    max-width: 450px;
    padding-top: 24px;
  }

  .screenshot-slick-container .screenshot-mock-2 {
    max-width: 154px;
  }

  .cps-section-header.style-2 .cps-section-title {
    font-size: 2.8rem;
    line-height: 3rem;
  }

  .page-header {
    padding: 0px 0 45px;
  }

  .post-list .post-content {
    margin-top: 20px;
  }

  .cps-countdown-item .cps-banner-form {
    max-width: 100%;
    width: 320px;
  }

  .comingsoon-banner .cps-banner-item,
  .comingsoon-banner .cps-banner-item .overlay {
    min-height: 620px;
  }

  .countdown-comingsoon .section_count + .section_count {
    margin-left: 15px;
  }

  .cps-banner.style-14 .cps-banner-item .cps-banner-content {
    margin-top: 30px;
  }

  .ticksy-cta {
    min-height: 535px;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cps-head-quote-section .cps-head-quote .cps-headquote-text {
    line-height: 1.2;
  }

  .cps-section-header.style-4 .cps-section-title {
    font-size: 2.6rem;
  }

  .cps-section-header.style-4 .cps-section-text br {
    display: none;
  }

  .bubble-fact-md {
    left: 15px;
  }

  .bubble-fact-sm {
    left: 260px;
  }

  .bubble-fact-xs {
    left: 187px;
  }

  .cps-banner.style-16 .cps-banner-title {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }

  .cps-banner.style-16 .cps-banner-text {
    margin-top: 10px;
  }

  .cps-banner.style-17 .cps-banner-title {
    font-size: 3.6rem;
  }

  .cps-banner.style-17 .cps-banner-text {
    margin-top: 0px;
  }

  .cps-banner.style-17 .button-group {
    margin-top: 10px;
  }
}
/*** Device less than 480px ***/
@media screen and (max-width: 479px) {
  .cps-banner.style-9 .cps-banner-form [type=email] {
    width: 100%;
  }

  .cps-banner.style-9 .cps-banner-form [type=submit] {
    float: none;
  }

  .integrated-with-wrap.style-2 {
    margin-bottom: -15px;
  }

  .integrated-with-wrap.style-2 .integrated-with {
    margin-bottom: 15px;
  }

  .subscription-style-3 .subscription-container {
    padding: 15px;
  }

  .subscription-style-3 .cps-subscription input:not([type=submit]):not([type=radio]):not([type=checkbox]),
  .subscription-style-3 .cps-subscription select,
  .subscription-style-3 .cps-subscription textarea {
    width: 100%;
    margin-bottom: 10px;
  }

  .subscription-style-3 .cps-subscription [type=submit] {
    width: 100%;
    position: static;
  }

  .connection-mock .logo-mock:after {
    display: none;
  }

  .connection-mock .logo-mock {
    padding: 75px;
  }

  .how-it-work-process .process-item {
    height: 150px;
    width: 150px;
    padding: 50px 15px;
  }

  .how-it-work-process .process-item + .process-item {
    margin-left: 0;
    margin-top: 20px;
  }
  .how-it-work-process .process-item + .process-item:before {
    content: none;
  }

  .mocked-features.style-2 .cps-section-title {
    font-size: 3.6rem;
  }

  .hiw-video-style-2 .cps-section-header .cps-section-title {
    font-size: 2.8rem;
  }

  .sm-play-btn {
    margin-top: 0;
  }

  .screenshot-slick-container {
    max-width: 270px;
    padding-top: 65px;
  }

  .screenshot-slick-container .screenshot-mock-2 {
    max-width: 310px;
    width: 310px;
  }

  .page-numbers + .page-numbers,
  a.page-numbers + .page-numbers {
    margin-left: 4px;
  }

  .page-numbers,
  a.page-numbers {
    width: 32px;
    height: 32px;
    line-height: 30px;
  }

  ul.nav.navbar-nav.navbar-social.navbar-right {
    position: static;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .cps-banner.style-15 .cps-banner-item .cps-banner-title {
    font-size: 3.6rem;
  }
  .cps-banner.style-15 .cps-banner-item .cps-banner-subtitle {
    font-size: 2.4rem;
  }

  .bubble-fact-sm {
    left: 120px;
    top: 222px;
  }

  .bubble-fact-xs {
    left: 13px;
  }

  footer .cps-footer-widget-area .col-xs-4 {
    width: 100%;
  }

  .custom-img-map .point-on-map {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    margin-bottom: 30px;
    margin-left: -50px;
  }
}
