.containerFluxoOportunidadesVendedor {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerFluxoOportunidadesVendedor label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerFluxoOportunidadesVendedor button {
    margin-left: 10px;
}

.left {
    float: left;
    width: 50%;
    box-sizing: border-box;
}

.right {
    float: right;
    width: 50%;
    box-sizing: border-box;
    z-index: -99999 !important;
}

.fff {
    background: #fff;
}

.eee {
    background: #eee;
}

.ddd {
    background: #ddd;
}