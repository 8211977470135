.layoutConfiguracao {
  margin-top: 50px;
}

.containerOptions {
  margin: 2rem;
  width: 100%;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.optionTitle {
  width: 18%;
}

.inputEmail {
  width: 300px;
}

.buttons {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.containerButtons {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .containerOptions {
    margin: 0;
  }

  .option {
    display: block;
    align-items: center;
    margin-bottom: 2rem;
  }

  .buttons {
    width: 100%;
    margin-top: 1rem;
    display: block;
  }

  .textInputNumber {
    margin-top: 1rem;
  }
}
