.containerVendasPerdidas {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerVendasPerdidas label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerVendasPerdidas button {
    margin-left: 10px;
}

.containerVendasPerdidas .ant-calendar-picker-input.ant-input {
    width: 250px;
}

.left {
    float: left;
    width: 50%;
    box-sizing: border-box;
}

.right {
    float: right;
    width: 50%;
    box-sizing: border-box;
}

.divTableGrafico {
    position: relative;
    margin-top: 50px !important;

}

.fff {
    background: #fff;
}

.eee {
    background: #eee;
}

.ddd {
    background: #ddd;
}