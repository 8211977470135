.meta .steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  /* text-align: center; */
  padding: 15px 10px;
  /* padding-top: 10px; */
  /* padding-left: 15px; */
  /* padding-right: 15px; */
}

.meta .steps-action {
  margin-top: 24px;
}

.meta input {
  padding-left: 11px !important;
}

.meta .input-number-total {
  width: 150px;
}

.meta .periodo-dias {
  width: 60px;
}

.meta .meta-periodo-porcentagem {
  width: 90px;
}

.meta .meta-periodo-valor {
  width: 115px;
  margin-left: 5px;
}

.meta .meta-periodo-valor input {
  padding-right: 11px !important;
}

.meta .meta-periodo-dias {
  width: 9rem;
  margin-left: 5px;
}

.meta .meta-periodo-dias input {
  padding-right: 11px !important;
}

.meta .totalRow {
  display: flex;
  width: 30.5rem;
  justify-content: space-between;
}

.meta .totalRow div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meta .rowError {
  position: absolute;
  left: 9rem;

  width: 20rem;
  margin-left: 1rem;
}

.meta h4 {
  margin-top: 0px;
}
