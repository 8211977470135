.cookieAlertBox{
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;

  margin: 2rem;
  padding: 2rem 2rem 3rem;
  width: 40rem;
  height: auto;

  border-radius: 1rem;
  background-color: #FAFAFA;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
}

.cookieAlertTitle{
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.1rem;
}

.cookieAlertText {
  margin-top: 1rem;

  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;

  color: #000;
}

.cookieAlertButton {
  display: block;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}
